import { Col, Container, Row, Tab, } from "react-bootstrap";
import Nav from 'react-bootstrap/Nav';
import { ProjectCard } from "./ProjectCard";
import colorSharp2 from "../assets/img/color-sharp2.png";
import img1 from "../assets/img/prntforgeSrc.png";
import img2 from "../assets/img/localeSrc.png";
import leet1 from "../assets/img/binaryTree.png";
import event1 from "../assets/img/IMG_20240505_111902.jpg"

export const Projects = () => {
    const projects = [
        {
            title: "3D Printing SaaS",
            description: "Worked as a Full Stack Development Intern on a Software as a Service for 3D Printing Businesses",
            imgUrl: img1,
        },
        {
            title: "Luxury Rentals Website",
            description: "Worked as a Full Stack Development Contractor on a Luxury Rentals Website",
            imgUrl: img2,
        },
    ];

    const leetCodes = [
        {
            title: "404 - Sum of Left Leaves",
            description: "Find the sum of all left leaves in a tree | Runtime: 0 ms (100%) Memory: 41 mb (47%)",
            imgUrl: leet1,
        },
        
    ];

    const events = [
        {
            title: "ASU Innovation Showcase",
            description: "Me at the ASU innovation showcase with my team at the end of our year long project",
            imgUrl: event1,
        },
        
    ];

    return (
        <section className="project" id="projects">
            <Container>
                <Row>
                    <Col>
                        <h2>Projects</h2>
                        <p>Each project I work on is a testament to my passion and dedication.</p>
                        <Tab.Container id="projects-tabs" defaultActiveKey="first">
                            <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                                <Nav.Item>
                                    <Nav.Link eventKey="first">
                                        <span className="desktop-show">Full-Stack Web Development</span>
                                        <span className="mobile-show">Full Stack</span>
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="second">
                                        Leet Codes
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="third">
                                        Events
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                            <Tab.Content>
                                <Tab.Pane eventKey="first">
                                    <Row>
                                        {
                                            projects.map((project, index) => {
                                                return (
                                                    <ProjectCard
                                                        key={index}
                                                        {...project}
                                                    />

                                                )
                                            })
                                        }
                                    </Row>
                                </Tab.Pane>

                            </Tab.Content>
                            <Tab.Content>
                                <Tab.Pane eventKey="second">
                                    <Row>
                                        {
                                            leetCodes.map((project, index) => {
                                                return (
                                                    <ProjectCard
                                                        key={index}
                                                        {...project}
                                                    />

                                                )
                                            })
                                        }
                                    </Row>
                                </Tab.Pane>

                            </Tab.Content>
                            <Tab.Content>
                                <Tab.Pane eventKey="third">
                                    <Row>
                                        {
                                            events.map((project, index) => {
                                                return (
                                                    <ProjectCard
                                                        key={index}
                                                        {...project}
                                                    />

                                                )
                                            })
                                        }
                                    </Row>
                                </Tab.Pane>

                            </Tab.Content>
                        </Tab.Container>
                    </Col>
                </Row>
            </Container>
            <img className="background-image-right" src={colorSharp2} alt={"bg"}></img>
        </section>
    )
}