import BlogPostCard from './BlogPostCard';
import { Container, Row } from "react-bootstrap";

const blogPostsData = [
    {
        id: 1,
        title: 'Unlocking the Potential of WordPress: Crafting Custom Themes and Plugins',
        body: `Hello there! Have you ever wondered about the magic behind WordPress websites? In this blog post, we'll embark on a journey into the fascinating world of WordPress development, exploring the secrets of creating custom themes and plugins.

        Custom themes and plugins are the heart and soul of WordPress customization. With custom themes, you can give your website a distinct personality and style that reflects your brand identity. Plugins, on the other hand, empower you to add new features and functionality to your site, turning your vision into reality.

        But fear not, crafting custom themes and plugins is easier than you might think. With a bit of creativity and know-how, you can unlock the full potential of WordPress and create a website that stands out from the crowd. So let's roll up our sleeves and dive into the world of WordPress development together!`,
    },
    {
        id: 2,
        title: 'Fortifying Your Web Applications: Implementing Best Practices for Web Development Security',
        body: `Greetings, fellow developer! If you're serious about web development, security should be at the top of your priority list. In this blog post, I'll share some essential tips for securing your web applications and keeping cyber threats at bay.

        First and foremost, let's talk about HTTPS. It's not just a protocol – it's a shield that protects your data from prying eyes. By serving your web applications over HTTPS, you can encrypt sensitive information and ensure safe communication between the server and the client.

        Input validation is another crucial aspect of web security. By validating user input, you can prevent common vulnerabilities like SQL injection and cross-site scripting attacks, keeping your applications safe from exploitation.

        And let's not forget about authentication and authorization. By implementing strong authentication mechanisms and role-based access control, you can ensure that only authorized users have access to your sensitive data and resources.

        So there you have it – a crash course in web development security. By following these best practices and staying vigilant against emerging threats, you can create a safe and secure online environment for your users. Happy coding!`,
    },
];



export const BlogPosts = () => {
    

    return (
        <Container>
            <Row>
                <div className="blog-posts" id="blogPosts">
                    <h2 className="blog-posts-heading">Blog Posts</h2>
                    {blogPostsData.map((post) => (
                        <Row key={post.id}>
                            <BlogPostCard post={post}/>
                        </Row>
                    ))}
                </div>
            </Row>
        </Container>
    );
};

