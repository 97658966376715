const BlogPostCard = ({ post, onClick }) => {
    const { title, body } = post;

    return (
        <div className="blog-post-card" onClick={() => onClick(post)}>
            <h3 className="blog-post-title">{title}</h3>
            <p>{body}</p>
        </div>
    );
};

export default BlogPostCard;